import React from "react";
import Styles from "./styles";
import IMobileMenu from "./types";
import { useIntl } from "gatsby-plugin-react-intl";
import { GatsbyImage } from "gatsby-plugin-image";
import useMobileMenuQuery from "./useMobileMenuQuery";

const { Wrapper, NavLink, LangBtn, LangBtnWrapper } = Styles;

const MobileMenu = ({ isMenuActive, setIsMenuActive }: IMobileMenu) => {
    const intl = useIntl();
    const { allFile } = useMobileMenuQuery();
    return (
        <Wrapper isMenuActive={isMenuActive} setIsMenuActive={setIsMenuActive}>
            <GatsbyImage
                style={{ width: "100%", height: "100%", position: "absolute" }}
                imgStyle={{
                    objectFit: "fill",
                    width: "100%",
                    position: "absolute",
                }}
                image={allFile.edges[0].node.childImageSharp.gatsbyImageData}
                alt="menu background"
            />
            <NavLink
                to="services"
                smooth={true}
                onClick={() => setIsMenuActive(false)}
            >
                {intl.formatMessage({ id: "aboutKiosk" })}
            </NavLink>
            <NavLink
                to="gallery"
                smooth={true}
                offset={360}
                onClick={() => setIsMenuActive(false)}
            >
                {intl.formatMessage({ id: "caseStudy" })}
            </NavLink>
            <NavLink
                to="contact"
                smooth={true}
                onClick={() => setIsMenuActive(false)}
            >
                {intl.formatMessage({ id: "contact" })}
            </NavLink>
            <LangBtnWrapper>
                <LangBtn to="/pl" onClick={() => setIsMenuActive(false)}>
                    PL
                </LangBtn>{" "}
                /{" "}
                <LangBtn to="/en" onClick={() => setIsMenuActive(false)}>
                    EN
                </LangBtn>
            </LangBtnWrapper>
        </Wrapper>
    );
};

export default MobileMenu;
