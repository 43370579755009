import { useStaticQuery, graphql } from "gatsby";

const useMobileMenuQuery = () => {
    const mobileMenuQuery = useStaticQuery(graphql`
        query mobileMenuQuery {
            allFile(filter: { name: { eq: "mobileMenuBackground" } }) {
                edges {
                    node {
                        childImageSharp {
                            gatsbyImageData(
                                layout: CONSTRAINED
                                placeholder: NONE
                            )
                        }
                    }
                }
            }
        }
    `);
    return mobileMenuQuery;
};

export default useMobileMenuQuery;
