import arrow from "./arrow.svg";
import arrows from "./arrows.svg";
import brainstorming from "./brainstorming.svg";
import creativity from "./creativity.svg";
import galleryArrow from "./galleryArrow.svg";
import idea from "./idea.svg";
import indicator from "./indicator.svg";

const Icons = {
    arrow,
    arrows,
    brainstorming,
    creativity,
    galleryArrow,
    idea,
    indicator,
};

export default Icons;
