import React from "react";
import GlobalStyles from "../../GlobalStyles";
import Navigation from "./Navigation";
import MobileMenuBtn from "../MobileMenuBtn";
import Head from "../Helmet";
import Styles from "./styles";
import KioskContextComponent from "./context";

const { PageWrapper } = Styles;

const Layout = ({ children }) => {
    return (
        <KioskContextComponent>
            <PageWrapper>
                <Head />
                <MobileMenuBtn />
                <GlobalStyles />
                <Navigation />
                {children}
            </PageWrapper>
        </KioskContextComponent>
    );
};

export default Layout;
