import React from "react";
import Styles from "./styled";
import { Container, SVGIcon } from "../../../Styled";
import Icons from "../../../images/icons";
import { useIntl } from "gatsby-plugin-react-intl";

const {} = Icons;
const { NavContainer, NavLink, LangBtnWrapper, LangBtn } = Styles;

const Navigation = () => {
    const intl = useIntl();
    return (
        <NavContainer>
            <NavLink to="services" smooth={true}>
                {intl.formatMessage({ id: "aboutKiosk" })}
            </NavLink>
            <NavLink to="gallery" smooth={true} offset={360}>
                {intl.formatMessage({ id: "caseStudy" })}
            </NavLink>
            <NavLink to="contact" smooth={true}>
                {intl.formatMessage({ id: "contact" })}
            </NavLink>
            <LangBtnWrapper>
                <LangBtn to="/pl">PL</LangBtn> / <LangBtn to="/en">EN</LangBtn>
            </LangBtnWrapper>
        </NavContainer>
    );
};

export default Navigation;
