import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
    @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;700&display=swap');

    body{
        font-family: "Montserrat";
        margin: 0px;
        padding: 0px;
        color: #FFE8D1;
        font-display: swap;
    }
`;

export default GlobalStyles;
