import React, { createContext, useReducer } from "react";
import { IKioskContext } from "../Landing/types";

const kioskContext = {
    kioskData: {
        isKioskHovered: false,
        isHandDragged: false,
        isAnimationPlaying: false,
        screenType: "reward",
        animationType: "",
        animationProgress: 0,
        handPointAx: 0,
        handPointAy: 0,
        handPointBx: 0,
        handPointBy: 0,
        handDropped: false,
    },
    dispatchKioskData: null,
};

export const KioskContext = createContext<IKioskContext>(kioskContext);

const KioskContextComponent = ({ children }) => {
    const [kioskData, dispatchKioskData] = useReducer(
        (state: object, payload: any) => ({ ...state, ...payload }),
        {
            isKioskHovered: false,
            isHandDragged: false,
            isAnimationPlaying: false,
            screenType: "landing",
            animationType: "progress100",
            animationProgress: 2,
            handPointAx: 0,
            handPointAy: 0,
            handPointBx: 0,
            handPointBy: 0,
            handDropped: false,
        }
    );

    return (
        <KioskContext.Provider value={{ kioskData, dispatchKioskData }}>
            {children}
        </KioskContext.Provider>
    );
};

export default KioskContextComponent;
