import React from "react";
import { Helmet } from "react-helmet";
import { useIntl } from "gatsby-plugin-react-intl";

const Head = () => {
    const intl = useIntl();
    return (
        <Helmet
            htmlAttributes={{
                lang: "pl",
            }}
        >
            <meta charSet="utf-8" />
            <meta
                name="description"
                content={intl.formatMessage({ id: "metaDesc" })}
            />
            <meta
                name="keywords"
                content={intl.formatMessage({ id: "keywords" })}
            />
            <title>{intl.formatMessage({ id: "title" })}</title>
            <link
                rel="canonical"
                href="https://oakfusion.gitlab.io/landingpage/gamification-kios-tv/"
            />
            <meta lang="pl" />
        </Helmet>
    );
};

export default Head;
